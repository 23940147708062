import React from 'react';
import { Disclosure } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/outline';
//import { useStyles } from "react-styles-hook"

const FaqForm = ({ question, answer }) => {

  function classNames(...classes) {
    return classes.filter(Boolean).join('');
  }

  return (
    <Disclosure as="div" className="pl-4">
      {({ open }) => (
        <>
          <dt className="text-lg">
            <Disclosure.Button className="text-left w-full flex justify-between items-start text-gray-dark hover:outline-none focus:outline-none active:outline-none">
              <span className="font-semibold text-grey-dark text-slate-500 text-s">{question}</span>
              <span className="ml-6 h-7 flex items-center">
                <ChevronDownIcon
                  className={classNames(open ? '-rotate-180 ' : 'rotate_0 ', 'h-6 w-6 transform ')}
                  aria-hidden="true" />
              </span>
            </Disclosure.Button>
          </dt>
          <Disclosure.Panel as="dd" className="mt-2 pr-12">
            <p className="text-gr text-xs text-grey-dark">{answer}</p>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  )
}

export default FaqForm

