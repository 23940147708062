import React from 'react';
import i18n from "../../../i18n";
import { withTranslation } from "react-i18next";
import { Helmet } from 'react-helmet';
import { graphql } from 'gatsby';
import { capitalizeFirst } from '../../utils/format';
import Layout from '../../components/Layout';
import FaqForm from '../../components/FaqForm';
import NavBarMenu from '../../components/NavBarMenu';
import CoverImage from '../../components/CoverImage';
import contactUs from '../../../assets/contactUs.jpg';
import generateMeta from '../../utils/meta';

const FaqPage = ({ data, pageContext, t }) => {
  // SEO
  const initMetas = [{
    name: 'og:url',
    content: `${process.env.GATSBY_WEBSITE_URL}${pageContext.currentPath}`,
  }];

  const metas = generateMeta(initMetas, data?.markdownRemark?.frontmatter);

  i18n(pageContext.locale);
  const sortedFirstLevelCategorySlugs = ["femme", "enfant", "homme", "coaching-capillaire"];

  let faqContentCategory = [];
  if (data.allContentfulFaqCategoryTitle) {
    faqContentCategory = data.allContentfulFaqCategoryTitle.edges;
  }

  let faqContents = [];
  if (data.allContentfulFaqQuestionsAnswers) {
    faqContents = data.allContentfulFaqQuestionsAnswers.edges;
  }

  return (
    <Layout
      lang={pageContext.locale}
      currentPath={pageContext.currentPath}
      firstLevelCategories={data.menuFirstLevel.edges.sort((firstLevelCategory1, firstLevelCategory2) => {
        return (
          sortedFirstLevelCategorySlugs.indexOf(firstLevelCategory1.node.slug) - sortedFirstLevelCategorySlugs.indexOf(firstLevelCategory2.node.slug)
        )
      })}
      secondLevelCategories={data.menuSecondLevel.edges}
      thirdLevelCategories={data.menuThirdLevel.edges}
    >
      <Helmet>
        <html lang="fr" />
        <title>{capitalizeFirst(t("faqPage.faq"))}</title>
        {/* Seo and OpenGraph */}
        {metas && metas.map(meta => (
          <meta key={meta.name} name={meta.name} content={meta.content} />
        ))}
      </Helmet>
      <NavBarMenu
        firstLevelCategories={data.menuFirstLevel.edges.sort((firstLevelCategory1, firstLevelCategory2) => {
          return (
            sortedFirstLevelCategorySlugs.indexOf(firstLevelCategory1.node.slug) - sortedFirstLevelCategorySlugs.indexOf(firstLevelCategory2.node.slug)
          )
        })}
        secondLevelCategories={data.menuSecondLevel.edges}
        thirdLevelCategories={data.menuThirdLevel.edges}
      />
      <CoverImage
        imageDesktop={contactUs}
        imageMobile={contactUs}
        title={"F.A.Q."}
        altDesktop="image faq"
        altMobile="image faq"
      />
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-6 mb-6 md:mb-10">
        <div className="max-w-3xl mx-auto">
          {
            faqContentCategory.map((category) => {
              return (
                <>
                  <h3 className="text-left text-xs md:text-base font-semibold text-gray-900 mt-14 pt-8">{category.node.categoryTitle}</h3>
                  <dl className="mt-6 pt-6 space-y-6">
                    {faqContents.map((content, key) => {
                      return (
                        (content.node.contentfulparent.categoryTitle === category.node.categoryTitle)
                        && (
                          <FaqForm
                            key={key}
                            question={content.node.question}
                            answer={content.node.answer.answer}
                          />
                        )
                      )
                    })}
                  </dl>
                </>
              )
            })
          }
        </div>
      </div>
    </Layout>
  )
};

export default withTranslation()(FaqPage);

export const query = graphql`
  query( $locale: String! ) {
    menuFirstLevel: allContentfulCategorySve(filter: { slug: { in: ["femme", "enfant", "homme", "coaching-capillaire"] }, node_locale: {eq: $locale}}) {
      edges {
        node {
          slug
        categoryName
        }
      }
    }
    menuSecondLevel: allContentfulCategorySve(filter: {parentCategory: { slug: { in: ["femme", "enfant", "homme", "coaching-capillaire"] }, node_locale: {eq: $locale}}}
    sort : {fields: [slug], order: ASC}) {
      edges {
        node {
          slug
            categoryName
            categoryEncartTitle
            parentCategory {
            slug
            categoryName
            categoryEncartTitle
          }
        }
      }
    }
    menuThirdLevel: allContentfulCategorySve(filter: {parentCategory: {parentCategory: { slug: { in: ["femme", "enfant", "homme", "coaching-capillaire"] }, node_locale: {eq: $locale}}}}
    sort : {fields: [slug], order: ASC}) {     
      edges {
        node {
          slug
          categoryName
          categoryEncartTitle
          parentCategory {
          slug
          categoryName
          categoryEncartTitle
          parentCategory {
            slug
            categoryName
            categoryEncartTitle
            }
          } 
        }
      }
    }
    allContentfulCategorySve(filter: { node_locale: { eq: $locale } }) { 
        edges {
          node {
            categoryName
            categoryEncartTitle
            slug
            parentCategory {
              categoryName
              categoryEncartTitle
            }
          }
        }
      }
    allContentfulFaqQuestionsAnswers(filter: { node_locale: { eq: $locale } })  {
      edges {
        node {
          question
          contentfulparent {
            categoryTitle
          }
          answer {
            answer
          }
        }
      }
    }
    allContentfulFaqCategoryTitle(filter: { node_locale: { eq: $locale } })  {
      edges {
        node {
          id
          categoryTitle
        }
      }
    }
    markdownRemark(fileAbsolutePath: {regex: "/faq.md/"}) {
      html
      frontmatter {
          title
          description
          ogtitle
          ogdescription
      }
    }
  }
`